import './App.css';
import styled from 'styled-components';
import rainfallLogo from './assets/images/Rainfall_Logo1.svg';
import lykkehjuletIcon from './assets/images/Hjulet.svg';
import terningspillIcon from './assets/images/Terninger.svg';

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: #f6f6f6;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
`;

const NavBar = styled.div`
  overflow: hidden;
  background-color: white;
  position: sticky;
  top: 0;
  width: 100%;
  height: 3em;
  z-index: 1;
  filter: drop-shadow(0 0 0.2em lightgray)
`;

const RainfallLogoImg = styled.img`
  position: absolute;
  height: 2em;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 2em;
`;

const UrlText = styled.div`
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 0;
  margin-right: 2em;
  font-size: 0.8em;
`;

const InnerContainer = styled.div`
  margin-top: 4em;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  width: 60em;

  @media only screen and (max-width: 1200px) { 
    width: calc(50em);
  }

  @media only screen and (max-width: 1024px) { 
    width: 40em;
  }

  @media only screen and (max-width: 768px) { 
    width: 25em;
  }

  @media only screen and (max-width: 480px) { 
    width: calc(100vw - 2em);
  }
`;

const GameLinks = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap: 1em;
  width: 100%;
`;

const Header = styled.h1`
  font-size: 1.2em;
`;

const Separator = styled.hr`
  background-color: lightgray;
  height: 0.1em;
  border: none;
  margin: 2em 0;
`;

const IconBox = styled.div`
  border-radius: 0.5em;
  width: 8em;
  height: 8em;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  filter: drop-shadow(0 0 0.2em lightgray);
  font-weight: 600;
  text-align: center;
`;


const IconImg = styled.img`
  width: 3.2em;
  margin-bottom: 0.5em;
`;

const Link = styled.a`
  text-decoration: none;
  color: black;
`;

const LykkehjuletIcon = () => {
  return (
    <IconBox>
      <IconImg src={lykkehjuletIcon} alt="Lykkehjulet Icon" />
      Lykkehjulet
    </IconBox>
  );
};

const TerningspillIcon = () => {
  return (
    <IconBox>
      <IconImg src={terningspillIcon} alt="Terningspill Icon" />
      Terningspill<br />Påske 2021
    </IconBox>
  );
};

const App = () => {
  return (
    <Container>

      <NavBar>
        <Link href="https://rainfall.no/">
          <RainfallLogoImg src={rainfallLogo} alt="Rainfall Logo" />
        </Link>

        <UrlText>raingames.no</UrlText>
      </NavBar>

      <InnerContainer>
        <Header>Tilgjengelige applikasjoner</Header>
        <Separator />
        <GameLinks>
          <Link href="https://lykkehjulet.raingames.no/" target="_blank">
            <LykkehjuletIcon />
          </Link>

          <Link href="https://dicegame.now.sh/paaske2021/" target="_blank">
            <TerningspillIcon />
          </Link>
        </GameLinks>
      </InnerContainer>
    </Container>
  );
};

export default App;
